import React from 'react';
import CTAButton from '../../components/CTAButton/CTAButton';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import YouTubeEmbed from '../../components/YouTubeEmbed/YouTubeEmbed';
import { useAAAIDPAnimationInfo } from '../../content/projects/aaa-idp-animation.project';
import { PageComponent } from '../../types/gatsby.types';
// import './ProjectPageJimOCallaghan.scss';

type ProjectPageAAAAIDPAnimationProps = {}

const ProjectPageAAAAIDPAnimation: PageComponent<ProjectPageAAAAIDPAnimationProps> = props => {
  const metaInfo = useAAAIDPAnimationInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
    sidebarExtraContent={
      <CTAButton to="https://www.youtube.com/watch?v=AKHuyMqV0fg" title="Watch on YouTube" target="_blank" ribbons drawLine="left">Watch on YouTube</CTAButton>
    }
  >
    <YouTubeEmbed
      src="https://www.youtube.com/embed/AKHuyMqV0fg"
      title="AAA IDP Introduction Video Player"
    />
  </ProjectPage>
}

export default ProjectPageAAAAIDPAnimation;